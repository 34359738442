.user-avatar {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $spacing;
  background-color: $color__neutral--50;
  transition: background-color 150ms ease;

  .user-avatar__text {
    color: $color__black;
    font-size: $font-size--small;
  }
}
