.widget {
  @container (min-width: 300px) {
    p.secondary {
      white-space: pre;
    }
  }

  h2 {
    div {
      margin-left: 0.8rem;
      position: relative;
      bottom: 1px;
      font-weight: 400;
      font-size: $font-size--base;
      color: $color__primary--500;
      display: inline;

      &.negative {
        color: $color__error--500;
      }

      span {
        color: $color__neutral--500;
        font-size: $font-size--secondary;
        font-weight: 300;
        margin-left: 0.4rem;
      }
    }
  }

  .chart-wrapper {
    min-height: 250px;
    margin-top: 0.8rem;
  }

  .datatable-wrapper {
    table {
      width: 100%;
    }

    th {
      font-size: $font-size--small;
      line-height: 1.4rem;
      font-weight: $font-weight--regular;
      color: $color__neutral--700;
      padding: 0 0 0.4rem 0;
    }

    td {
      font-size: $font-size--secondary;
      line-height: 2rem;
      font-weight: $font-weight--light;
      padding: 0.4rem 0;
    }

    tr {
      vertical-align: top;

      th:nth-child(2),
      td:nth-child(2) {
        padding-left: 0.4rem;
        text-align: right;
      }

      th:nth-child(3),
      td:nth-child(3) {
        padding-left: 1.6rem;
        text-align: right;
      }
    }
  }

  .chart-header {
    display: flex;
    align-items: center;

    .chart-header--title {
      h5 {
        color: $color__black;
      }

      .tooltip {
        margin-left: 4px;

        i {
          color: $color__green;
        }
      }
    }

    p {
      color: $color__grey;
      text-align: right;
      min-width: 100px;
    }
  }

  ol {
    gap: 0.4rem;

    li {
      font-size: $font-size--secondary;

      small {
        color: $color__neutral--500;
      }
    }
  }

  a:has(img),
  a:has(img):hover {
    border-bottom: none;
  }
}
