.inline-kebab {
  display: inline-flex;
  width: 1.6rem;
  justify-content: center;

  .icon {
    color: $color__green;
    margin-right: 0;
    font-size: 2.2rem;
  }

  &,
  &:hover,
  &:active {
    border-bottom: none;
  }
}
