.card {
  background: rgba($color__white, 0.5);
  border: 1px solid $color__neutral--100;
  padding: $spacing-el-small;
  border-radius: $border-radius;
  @include standardGap();

  h4 {
    color: $color__primary--900;
  }

  &.loading {
    min-height: 150px;
  }
}

.content__primary .card,
.primary-card {
  background: $color__white;
  border: 1px solid $color--fainter-border;
}

.card-divider {
  margin-top: $spacing-el-xsmall;
  padding-top: $spacing-el-xsmall;
  border-top: 1px solid $color__neutral--200;
}

.card__two-column {
  display: flex;
  justify-content: space-between;

  > *:first-child {
    flex: 0 0 40%;
  }

  > *:last-child {
    flex: 0 0 55%;
  }
}

.card.full-width-card {
  // 2px gap at the bottom to avoid line-item backgrounds
  // overlapping the rounded border
  padding: $gap 0 2px;

  #search-form {
    padding: 0 $gap;
  }

  .placeholder {
    margin: 0 $gap $gap;
  }
}
