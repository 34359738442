.clients {
  &.show {
    #origin {
      height: 4rem;
      width: 4rem;
      padding: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}
