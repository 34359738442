.version-history {
  @include standardGap();

  .version {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .version__meta {
      color: $color__neutral--700;
    }

    .version__title {
      span {
        font-weight: $font-weight--regular;
      }
    }

    .version__details ul {
      list-style: circle;

      li {
        margin-left: $spacing;

        small {
          color: $color__neutral--700;
        }

        span {
          color: $color__black;
        }

        & + li {
          margin-top: 4px;
        }
      }
    }
  }

  .see_more {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
