.guideline-fee-totals {
  .guideline-list-header {
    &:last-child .guideline-item__price input {
      font-weight: $font-weight--regular;
      color: $color__black;
    }

    &:not(:last-child) {
      h4 {
        font-weight: $font-weight--regular;
      }
    }
  }

  .guideline-item {
    padding: $spacing-el-small 0 0;
    border-bottom: none;
  }
}

// as we're using turbo frames, this is the actual
// parent element
#project_totals_calculator {
  flex: 0 0 40%;
}
