.development,
.test {
  &:before {
    position: fixed;
    left: -2.7rem;
    top: -0.9rem;
    z-index: 99999;
    display: block;
    transform: rotate(-45deg);
    width: 8rem;
    padding-top: 2rem;
    background: $color__green;
    color: $color__white;
    text-align: center;
  }
}
.development:before {
  content: 'DEV';
}
.test:before {
  content: 'TEST';
}

.layout {
  width: 100vw;
  height: 100vh;
  display: flex;

  main {
    flex: 1 0 calc(100vw - 7.2rem);
    overflow: auto;

    @media (min-width: $large-up) {
      flex: 1 0 calc(100vw - 25.5rem);
    }
  }
}

.content {
  padding: 0 calc(#{$spacing} / 2);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;

  @media (min-width: $large-up) {
    padding: 0 $spacing;
  }

  .content__primary {
    flex: 1 1 100%;

    @media (min-width: $large-up) {
      flex: 1 1 60%;
    }
  }

  .content__secondary {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    gap: $spacing;

    @media (min-width: $large-up) {
      flex: 0 0 40%;
    }
  }

  .content__header {
    padding: $spacing calc(#{$spacing} / 2);
    width: 100%;

    @media (min-width: $large-up) {
      padding: $spacing;
    }

    @media (max-width: $tiny-only) {
      > .flex--justify-between {
        flex-direction: column;
        gap: $spacing;
        align-items: flex-start;
      }
    }

    h2 {
      color: $color__primary--900;
      flex: 1;
    }

    a.btn {
      align-self: center;
    }
  }

  .content__primary,
  .content__secondary {
    position: relative;
    padding: 0 calc(#{$spacing} / 2) $spacing;
    transition: filter 0.3s ease;

    @media (min-width: $large-up) {
      padding: 0 $spacing $spacing;

      // Overwrite this in page-specific scss if header height differs
      &.scroll {
        max-height: calc(100vh - 8rem);
      }
    }
  }

  &--centered {
    .content__header {
      h2 {
        text-align: center;
      }
    }

    .content__primary {
      flex: 0 1 70rem;
      padding-right: $spacing;
    }

    .content__secondary {
      flex: 0 0 auto;
    }
  }
}

// TODO: phase this deviation out in the future
.update_requests .content {
  .content__primary {
    order: 2;
  }

  .content__secondary {
    order: 1;
  }
}
