.insight {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
}

.recent-matters {
  @include standardGap();

  a {
    &:hover {
      background-color: $color__primary--50;
      border-bottom-color: transparent;
    }
  }

  span {
    display: flex;
    flex-direction: column;
    font-size: $font-size--secondary;
  }
}
