.page-header {
  flex: 1 auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color__neutral--50;
  padding: $spacing-el-small $spacing-el-tiny;
}

.page-header__nav-back {
  margin-right: $spacing-el-default;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border: 1px solid $color__neutral--200;
    border-radius: $border-radius;
    color: $color__neutral--200;
    transition: all 150ms ease;

    i {
      font-size: 1.8rem;
    }

    &:hover {
      border-color: $color__attention--500;
      color: $color__attention--500;
    }
  }
}

.page-header__content {
  display: flex;
  flex-direction: column;

  h3 {
    color: $color__primary--900;
  }

  small {
    font-size: 1rem;
    color: $color__neutral--700;
    text-transform: uppercase;
    margin-top: 2px;
  }
}

.page-header--projects {
  padding: $spacing-el-small 0 $spacing-el-small 0;
}

.page-header.page-header--guideline {
  padding: $spacing-el-small 0;
  display: flex;
  align-items: center;
}

.content-header {
  flex: 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-el-small $spacing-el-tiny 0;
}
