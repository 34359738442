.notes_component {
  @include standardGap();
}

.notes_component_list {
  @include standardGap();
}

.notes_item_component {
  display: flex;

  .user-avatar {
    width: 30px;
    height: 30px;
    margin: 4px $spacing-el-small 0 0;
  }

  .note__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
  }

  .note__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .note__meta {
    display: flex;
    flex-direction: column;
    gap: 4px;

    small {
      color: $color__neutral--700;
    }
  }

  .secondary {
    overflow: overlay;
  }
}

.notes_form_component {
  position: relative;

  textarea {
    padding-right: 42px;
  }

  [type='submit'] {
    position: absolute;
    right: 0.4rem;
    bottom: 0.4rem;
    width: 2.8rem;
    height: 2.8rem;

    i {
      font-size: 1.4rem;
    }
  }
}
