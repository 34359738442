#flash {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: $spacing-el-xlarge;
  pointer-events: none;
}

.flash {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: $color__success--500;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 15%),
    0px 4px 8px rgba(0, 0, 0, 20%);
  border-radius: $border-radius;
  padding: $spacing-el-small $spacing-el-xlarge;
  width: 75%;
  max-width: 500px;
  margin-top: 1rem;
  pointer-events: all;
}

.flash--alert {
  background-color: $color__error--900;
}

.flash--notice {
  background-color: $color__primary--500;
}

.fade-out-flash {
  animation: fade-out ease-out 3s;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
