.panel {
  position: fixed;
  right: -80vw;
  top: 0;
  bottom: 0;
  margin-top: 8rem;
  width: 50vw;
  height: calc(100vh - 7.2rem);
  border-style: solid;
  border-color: $color__neutral--100;
  border-width: 1px 0 0 1px;
  border-radius: 4px 0 0 0;
  transition: all 0.3s ease;
  z-index: 101;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  overflow-y: auto;

  @media screen and (min-width: $large-up) {
    right: -40vw;
    width: 32vw;
  }

  &.show {
    right: 0;
  }

  &:not(.loading) .spinner {
    display: none;
    opacity: 0;
  }

  &.loading .spinner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.4s ease;
  }

  &__content {
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;

    > header {
      padding: 0.8rem $spacing-el-small;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $color__neutral--200;
    }

    > section {
      padding: $spacing;
      overflow: auto;
      height: calc(100vh - 13rem);

      &.with_footer {
        height: calc(100vh - 18.2rem);
      }
    }

    > footer {
      padding: $spacing-el-small;
      position: sticky;
      bottom: 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $color__neutral--200;
      background-color: white;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
      line-height: 1;

      a {
        font-size: $font-size--small;
        line-height: 1.4rem;
        display: flex;
        align-items: center;
      }

      button {
        font-size: $font-size--small;
      }
    }
  }

  &__close {
    color: $color__neutral--200;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color__neutral--200;
    border-radius: 5px;
    transition:
      border-color 0.2s ease,
      color 0.2s ease;

    &:hover {
      color: $color__green--light;
      border-color: $color__green--light;
      cursor: pointer;
    }
  }

  &.loading {
    .panel__content {
      opacity: 0;
    }
  }

  #search-form {
    margin-bottom: $gap;

    .toggle {
      margin-bottom: -1.6rem;
    }
  }
}

.panel.show ~ .layout .content__secondary,
.update_requests .panel.show ~ .layout .content__primary {
  filter: blur(3px);
}

// update requests uses a reversed content panel order
.update_requests .panel.show ~ .layout .content__secondary {
  filter: none;
}

.modal .spinner {
  display: none;
}
