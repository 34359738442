.factor.inline-form,
.base_fee.inline-form {
  .factor__name {
    flex: 1;

    textarea {
      width: 100%;
    }
  }

  .factor__count {
    position: relative;

    input {
      width: 60px;
      padding-right: 18px;
      text-align: right;
    }

    span {
      position: absolute;
      top: 8px;
      right: 6px;
      color: $color__neutral--400;
    }
  }

  .factor__price {
    margin-right: 2.5rem;

    span {
      color: $color__neutral--400;
      font-weight: $font-weight--light;
    }

    input {
      width: 85px;
      text-align: right;
    }
  }

  .factor__edit {
    margin-left: -2.4rem;
  }
}
