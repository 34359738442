// =========================
// Utilities: Flexbox
// =========================

.flex {
  display: flex;
}

.flex--column {
  display: flex;
  flex-direction: column;
}

.flex--justify-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.flex--justify-end {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.flex--align-start {
  display: flex;
  align-items: start;
}

.flex--align-center {
  display: flex;
  align-items: center;
}

.flex--align-end {
  display: flex;
  align-items: flex-end;
}

.gap {
  gap: $gap;
}

.half-gap {
  gap: calc(#{$gap} / 2);
}

.double-gap {
  gap: calc(#{$gap} * 2);
}

@mixin standardGap {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.has-standard-gap {
  @include standardGap();
}

// =========================
// Utilities: Text Alignment
// =========================
.text--align-left {
  text-align: left;
}

.text--align-center {
  text-align: center;
}

.text--align-right {
  text-align: right;
}

// =========================
// Utilities: Display Properties
// =========================
.display--hidden {
  display: hidden;
}

.is-fullwidth {
  width: 100%;
}

.is-autowidth {
  width: auto;
}

// =========================
// Utilities: Margins and Padding
// =========================
.margin-top-standard {
  margin-top: $spacing-el-default;
}

.margin-top-small {
  margin-top: $spacing-el-small;
}

.margin-top-xsmall {
  margin-top: $spacing-el-xsmall;
}

.margin-top-tiny {
  margin-top: $spacing-el-tiny;
}

.margin-bottom-standard {
  margin-bottom: $spacing-el-default;
}

.margin-bottom-small {
  margin-bottom: $spacing-el-small;
}

.negative-bottom-standard {
  margin-bottom: -$spacing;
}

.margin-padding-none {
  margin: 0;
  padding: 0;
}

.padding-bottom-standard {
  padding-bottom: $spacing-el-default;
}

// =========================
// Utilities: Borders
// =========================

.border-bottom-standard {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: $color__neutral--200;
}

// =========================
// Utilities: Links and Buttons
// =========================

// display any button or text as a link
.is-link {
  padding: 0;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  background: none;
  transition: all 0.2s ease;
  color: $color__green;
  line-height: 1.5;

  &:hover {
    background: none;
    border: none;
    color: $color__green;
    border-bottom: 1px solid $color__green;

    i {
      color: $color__green;
    }
  }
}

// =========================
// Utilities: Type
// =========================
.font-weight--regular {
  font-weight: $font-weight--regular;
}

.font-weight--light {
  font-weight: $font-weight--light;
}

// =========================
// Utilities: General
// =========================

.clickable {
  cursor: pointer;
}

// standard spacing for a horizontal row of buttons
.button-row {
  margin-top: $spacing-el-small;

  button + button {
    margin-left: $spacing-el-small;
  }
}

.hidden {
  display: none;
}

.ghost {
  opacity: 0;
  pointer-events: none;
}

.gap-xsmall {
  gap: $spacing-el-xsmall;
}

// Turbo loading bar color
.turbo-progress-bar {
  background-color: $color__green;
}

// add a standard spacing structure for primary content
.content__primary--container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

// Generic Table Styling

table.table {
  width: 100%;
  table-layout: auto;

  tr {
    border-top: 1px solid $color__neutral--200;

    &:last-child {
      border-bottom: 1px solid $color__neutral--200;
    }

    td {
      font-weight: 300;
      font-size: $font-size--base;
      line-height: 24px;
    }
  }
}
