@import 'bookmarks';
@import 'index_list';
@import 'index';
@import 'layout';
@import 'list_item_header';
@import 'list_item';
@import 'multiplier_tabset';

.guidelines {
  .guideline_row {
    td:first-child {
      width: 0;
    }

    .bookmarks__action {
      margin: 0;

      .icon {
        margin: 0;
      }

      .bookmark__text {
        display: none;
      }
    }
  }
}
