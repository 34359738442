.guideline-item__container:hover .factor-multiplier-tabset,
.guideline-item__container:focus .factor-multiplier-tabset {
  display: flex;
}

.factor-multiplier-tabset {
  display: none;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 1.2rem;
  display: none;
  flex-basis: 100%;

  .switch-field {
    margin-left: 6px;
    display: flex;
    overflow: hidden;
    border-radius: $border-radius;
    background-color: rgba(#f5fbfb, 0.75);
    padding: 4px;

    label {
      padding: 4px 6px;
      color: $color__black;
      transition: all 0.1s ease-in-out;
      border-radius: 8px;

      &:hover {
        cursor: pointer;
        background-color: $color__primary--50;
      }

      & + label {
        margin-left: 6px;
      }
    }

    input {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      width: 1px;
      border: 0;
      overflow: hidden;

      &:checked + label {
        background-color: $color__primary--50;
      }

      &:checked {
        background-color: red;
      }
    }
  }
}

.guideline-item__multiplier {
  display: flex;
  margin-right: $spacing-el-tiny;

  input {
    width: 30px;
    text-align: center;
    background-color: transparent;
  }
}
