.guideline-list-item {
  border-bottom: 1px solid $color__neutral--50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  &.list-item--link {
    padding: 0 $gap;
    display: flex;

    form:not(.button_to) {
      padding: $spacing-el-small $spacing-el-tiny;
    }

    textarea {
      font-size: 1.4rem;
      background: none;
    }

    &:hover {
      background-color: $color__neutral--50;

      span {
        color: $color__green--light;
      }
    }

    @media (max-width: $tiny-only) {
      align-items: flex-start;
    }
  }

  a {
    flex: 1 0;
    display: flex;
    padding: $spacing-el-small $spacing-el-tiny;
    color: unset;
    border-bottom: unset;
    transition: background-color 150ms ease;

    @media (max-width: $tiny-only) {
      flex-direction: column;
    }
  }

  span {
    flex: 0 0 20%;
    font-size: $font-size--secondary;
    font-weight: $font-weight--light;

    @media (min-width: calc($tiny-only + 1)) {
      & + span {
        padding-left: $spacing-el-xsmall;
      }
    }

    &.guideline-list-item__client {
      flex-grow: 1;
      font-size: $font-size--base;
      font-weight: $font-weight--regular;
    }

    &.guideline-list-item__client-id {
      flex: 0 0 100px;
    }

    &.guideline-list-item__guideline {
      flex-grow: 1;
      font-weight: $font-weight--light;
    }

    &.guideline-list-item__updated {
      flex: 0 0 auto;

      @media (min-width: calc($tiny-only + 1)) {
        text-align: right;
      }
    }

    &.guideline-list-item__owner {
      flex: 0 0 150px;
    }

    &.guideline-list-item__date {
      font-size: $font-size--small;
      flex: 0 0 150px;
      text-align: right;
    }

    &.guideline-list-item__insights {
      flex: 0 0 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: $color__neutral--700;
    }
  }

  & + .guideline-list__header {
    margin-top: $spacing-el-medium;
  }
}
