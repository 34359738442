.exportable-project {
  padding: $spacing-el-default 0;
  margin-bottom: -1px;
  border-width: 1px 0;
  border-style: solid;
  border-color: $color__neutral--500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ul {
    list-style-type: disc;
    padding-left: $spacing-el-default;

    li {
      font-size: $font-size--secondary;
    }
  }

  &.first {
    margin-top: $spacing-el-default;
  }

  &.last {
    margin-bottom: $spacing-el-default;
  }
}
