.activity-feed-item {
  padding: $spacing-el-default 0;
  border-bottom: 1px solid $color__neutral--50;
  display: flex;

  .activity-feed-item__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 24px;
    height: 24px;
    margin-right: $spacing-el-small;

    i {
      color: $color__neutral--200;
      font-size: 1.6rem;
    }
  }

  .activity-feed-item__title {
    span {
      font-weight: $font-weight--regular;
    }
  }

  p {
    font-size: 1.4rem;
    font-size: $color__black;
    margin-bottom: 0;

    a {
      font-size: 1.4rem;
      border-bottom: 1px solid transparent;
      color: $color__green--light;
      font-weight: $font-weight--regular;

      &:hover {
        border-bottom: 1px solid darken($color__green--light, 5%);
      }
    }

    &.activity-feed-item__content {
      margin-top: $spacing-el-tiny;

      a {
        display: block;
      }

      small {
        font-weight: $font-weight--light;
      }
    }
  }

  small {
    color: $color__neutral--700;
  }

  &:last-of-type {
    border-bottom: 0;
  }
}

.activity-feed__insight-content {
  border-left: 2px solid $color__neutral--50;
  padding-left: $spacing-el-xsmall;
}
