.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease;
  padding: $spacing;

  &.show {
    opacity: 1;
    pointer-events: all;
    background: rgba(0, 0, 0, 0.15);

    .modal__content {
      top: 0;
    }
  }

  &__content {
    position: relative;
    top: 100vh;
    transition:
      opacity 0.2s ease,
      top 0.2s ease;
    overflow-y: auto;
    width: 100%;

    @media (min-width: $small-only) {
      width: 60rem;
    }
  }

  &.loading {
    .modal__content {
      opacity: 0;
    }
  }
}

.modal .modal-actions > *:not(:last-child) {
  margin-right: $spacing;
}
