.guideline-block + .guideline-block {
  margin-top: $spacing-el-default;
}

.guideline-actions {
  flex: 1;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  a {
    margin-top: $spacing-el-tiny;
  }
}
