.btn {
  padding: 0.7rem 1.6rem;
  color: $color__neutral--700;
  background-color: white;
  border-radius: $border-radius;
  border: 1px solid $color__neutral--700;
  transition:
    background-color 0.2s ease,
    border-color 0.2s ease,
    color 0.2s ease,
    opacity 0.2s ease;
  font-size: $font-size--secondary;
  line-height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;

  &:not(:has(.icon)) {
    min-width: 10rem;
  }

  .icon,
  i {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    transition: color 0.2s ease;

    &:first-child:not(:last-child) {
      margin-right: 0.6rem;
      margin-left: -0.6rem;
    }

    &:last-child:not(:first-child) {
      margin-right: -0.6rem;
      margin-left: 0.6rem;
    }
  }

  &:hover {
    border: 1px solid $color__primary--500;
    background-color: $color__primary--500;
    color: white;

    .icon {
      color: white;
    }
  }

  &:disabled,
  &:disabled:hover {
    color: $color__neutral--700 !important;
    background-color: $color__neutral--50;
    border: 1px solid $color__neutral--700;
    opacity: 0.5;
    cursor: not-allowed;

    .icon {
      color: $color__neutral--700 !important;
    }
  }

  &--full {
    width: 100%;
  }

  &--primary {
    background-color: $color__green;
    border: 1px solid $color__green;
    color: $color__white;
  }

  &--danger {
    color: $color__error--900;

    .icon,
    i {
      color: $color__error--900;
    }

    &:hover {
      background-color: $color__error--900;
      border: 1px solid $color__error--900;

      .icon,
      i {
        color: $color__white;
      }
    }
  }

  &--subtle {
    background-color: transparent;
    border: 1px solid transparent;

    &:hover {
      color: $color__neutral--700;
      background-color: $color__neutral--50;
      border: 1px solid $color__neutral--50;

      .icon,
      i {
        color: inherit;
      }
    }

    &:disabled,
    &:disabled:hover {
      border: 1px solid transparent;
    }
  }
}
