.option__header {
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}

.option__name {
  flex-grow: 1;

  input {
    width: 100%;
  }
}

.option__value {
  width: 10rem;

  input {
    width: 10rem;
    padding-right: 2rem;
    text-align: right;
  }

  span {
    margin-left: -1.6rem;
  }
}

.modifier__options-list {
  &:empty {
    display: none;
  }

  &:not(:empty) + .placeholder {
    display: none;
  }
}
