.clio-activity-summary {

  color: $color__neutral--900;
  width: 100%;
  padding: 0 1.6rem;

  table {
    width: 100%;

    th {
      font-weight: $font-weight--regular;
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 62%
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 15%
    }

    th:nth-child(3),
    td:nth-child(3) {
      white-space: no-wrap;
    }

    tr {
      border-bottom: 1px solid black;
    }

    tr.totals {
      font-weight: $font-weight--medium;
    }

    margin-bottom: 2.1rem;
  }
}
