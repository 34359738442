.setting-header {
  border-bottom: 1px solid $color__neutral--50;
  padding-bottom: 0.8rem;
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin-top: 2.4rem;
}

.setting-row {
  border-bottom: 1px solid $color__neutral--50;
  padding: 0.8rem 0;
}

.setting-name {
  flex-grow: 1;
  font-weight: 300;
}

.setting-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
}
