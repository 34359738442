.update-request-suggestion {
  display: flex;
  flex-direction: column;
  width: 65%;
  max-width: 500px;

  textarea {
    margin-bottom: $spacing-el-small;
    border-radius: $border-radius;
    width: 100%;
    border: 1px solid $color__neutral--500;
    padding: $spacing-el-small;
    resize: none;

    &:focus,
    &:hover {
      border: 1px solid $color__attention--500;
    }
  }
}
