.notification__single {
  // use negative margins and padding for a proper unread notifications highlight background
  margin: $spacing #{-1 * $spacing} #{-1 * $spacing};
  padding: $spacing;
  border-bottom: 1px solid $color__neutral--50;

  &:first-of-type {
    margin-top: #{-1 * $spacing};
  }

  .title {
    font-size: $font-size--secondary;

    span {
      font-weight: 500;
    }
  }

  .body {
    margin: $spacing-el-xsmall 0;
    font-weight: 300;
    font-size: $font-size--small;

    a {
      font-weight: 400;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color__neutral--700;
    font-size: $font-size--small;
  }

  &.unread {
    background-color: $color__attention--50;
  }
}

#notifications .card.widget {
  background-color: $color__attention--50;
  gap: 0px;

  .notification__single {
    margin-top: 0px;
    margin-bottom: 0px;
    border-bottom-color: $color__neutral--300;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }
}
