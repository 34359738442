.table-list {
  width: 100%;
  table-layout: auto;

  .table-list__header {
    th:first-child {
      padding-left: $gap;
    }

    th:last-child {
      padding-right: $gap;
    }
  }

  tr:not(.table-list__header) {
    border-top: 1px solid $color__neutral--200;

    &:nth-child(odd) {
      background-color: $color__neutral--10;
    }

    &:hover {
      background-color: $color__neutral--50;
    }

    &.disabled,
    &.disabled:hover {
      color: $color__neutral--700;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  th {
    font-weight: 400;
    font-size: $font-size--small;
    text-align: left;
  }

  td {
    &:first-child {
      width: 30%;
      padding-left: $gap;
    }

    &:last-child {
      padding-right: $gap;
    }

    a:not(.btn) {
      border-bottom: none;
      font-weight: 400;
    }
  }

  &.table-list__full_width {
    tr:not(.table-list__header) {
      cursor: unset;
    }

    td:first-child {
      width: 100%;
    }

    td:last-child {
      display: flex;
    }
  }
}

table,
td,
th {
  border-collapse: collapse;
}

th,
td {
  padding: 0.8rem;
  text-align: left;
}

.clients.index {
  .table-list td.logo {
    width: 16px;
  }

  .table-list td.client-name {
    width: auto;
  }
}
