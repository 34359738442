.scroll-track {
  display: flex;
  justify-content: space-between;
  width: auto;
  padding: 0.4rem 1.6rem;
  transition: background 0.2s ease;

  &:hover {
    color: $color__green;
    border-bottom: 1px solid transparent;
    background-color: $color__primary--50 !important;
  }

  span:last-child {
    white-space: nowrap;
  }
}
