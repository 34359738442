.toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  small {
    cursor: pointer;
    margin-left: $spacing-el-tiny;
  }
}

.toggle__label {
  display: flex;
  align-items: center;
}

.toggle-container {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 14px;
  transition: opacity 0.25s ease;
}

.toggle__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border: 1px solid $color__green;
  transition: all 250ms ease;
  border-radius: 7px;

  &::before {
    position: absolute;
    content: '';
    height: 6px;
    width: 6px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border: 1px solid $color__green;
    transition: all 250ms ease;
    border-radius: 50%;
  }
}

.toggle__label input {
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .toggle-container .toggle__slider {
    background-color: $color__green;

    &::before {
      transform: translateX(8px);
    }
  }

  &:disabled + .toggle-container {
    opacity: 0.5;

    .toggle__slider {
      border-color: $color__neutral--500;

      &::before {
        border-color: $color__neutral--500;
      }
    }
  }

  &:checked:disabled + .toggle-container .toggle__slider {
    background-color: $color__neutral--500;
  }
}
