.record_owners {
  .owners_list {
    margin-bottom: 0.8rem;

    .owner {
      display: flex;
      font-size: $font-size--secondary;
    }

    .remove-owner {
      margin-left: 4px;
      transition: all 150ms ease;
      border: none;
      color: $color__neutral--500;

      &:hover {
        color: $color__primary--500;
        border: none;

        .icon {
          color: $color__primary--500;
        }
      }

      .icon {
        margin: 0;
      }
    }
  }

  .owners_form_toggle.show {
    display: none;

    & + .owners_form {
      display: block;
    }
  }

  .owners_form {
    display: none;
    position: relative;

    input {
      width: 100%;
      background-color: transparent;
      border: 1px solid transparent;
      border-bottom: 1px solid $color__green;
      padding: 6px 0;
      font-weight: 300;
      box-shadow: none;

      &:hover {
        border: 1px solid transparent;
        border-bottom: 1px solid $color__primary--500;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
