.masonry {
  width: 100%;
  position: relative;

  @media screen and (max-width: $phone-only) {
    min-width: unset !important;
  }

  > * {
    position: absolute;

    @media screen and (max-width: $phone-only) {
      max-width: 100%;
    }
  }

  .card {
    width: 100%;
  }
}
