.textfield,
select {
  @include inputStyle();
  width: 100%;

  &::placeholder {
    color: $color__neutral--700;
  }

  & + .textfield,
  & + label {
    margin-top: $spacing-el-small;
  }

  &:disabled,
  &:disabled:hover {
    color: $color__neutral--700 !important;
    background-color: $color__neutral--50;
    border: 1px solid $color__neutral--700;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.select-wrapper {
  position: relative;

  select {
    appearance: none;
    cursor: pointer;
    padding-right: 2.5rem;

    & + span::after {
      content: '^';
      margin-top: -1.5rem;
      font-size: 3rem;
      transform: rotate(180deg);
    }
  }

  span {
    position: absolute;
    right: 0.5rem;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.4rem;
    width: 2rem;
    pointer-events: none;
  }
}

input:focus,
input:hover,
textarea:focus,
textarea:hover,
select:focus,
select:hover {
  outline: none !important;
  border: 1px solid $color__green;
  box-shadow: 0 0 0 2px rgba($color__green, 0.2);
}

textarea {
  display: block;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}

label {
  display: block;
  color: $color__black;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-bottom: 0.4rem;
}
