.user-settings__tabs {
  margin: $spacing-el-medium 0;
  align-items: center;
}

.user-settings__block {
  & + .user-settings__block {
    margin-top: $spacing-el-medium;
  }

  .guideline-list-header {
    padding-left: 0;
    margin-bottom: $spacing-el-small;
  }
}

.user-settings__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.users {
  .content__header {
    display: flex;
  }
}
