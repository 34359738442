.inline-form {
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.6rem;
  gap: 8px;
  border-bottom: 1px solid $color__neutral--50;
  background: $color__white;

  &.hide {
    opacity: 0.4;
  }

  textarea,
  input {
    @include factorInputStyle();
  }

  &:has(.input-checkbox:checked) {
    textarea,
    input {
      color: $color__neutral--900;
    }
  }

  .checkbox-item .checkbox span {
    border-radius: $border-radius;
  }

  .inline-form__kebab {
    display: flex;
    align-items: center;

    a {
      display: inline-flex;
      width: 1.6rem;
      justify-content: center;

      .icon {
        color: $color__green;
        margin-right: 0;
        font-size: 2.2rem;
      }

      &,
      &:hover,
      &:active {
        border-bottom: none;
      }
    }
  }

  .inline-form__delete {
    width: 3.2rem;

    a {
      padding: 0 0.8rem;
      color: $color__neutral--200;
      border-bottom: none;

      &:hover,
      &:active {
        text-decoration: none;
        color: $color__error--900;
        border-bottom: none;
      }

      i {
        margin: 0;
      }
    }
  }
}

// only apply to factor rows on the matters page
body.matters .inline-form {
  textarea,
  input {
    color: $color__neutral--500;
  }
}
