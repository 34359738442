.matters {
  .estimate + .placeholder {
    display: none;
  }

  &.show {
    // TODO: move error highlight styles to factor-specific scss when factors are rebuilt
    .error-highlight {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  #matter_fee_totals {
    @include standardGap();

    .matter-fee-totals--header {
      align-items: baseline;
    }

    .project_price {
      position: relative;
      align-items: center;
      margin: 0 -1.6rem;

      .scroll-track {
        flex-grow: 1;
      }
    }
  }

  #export_dos {
    margin-bottom: $spacing;

    table {
      width: 100%;
      margin-top: 1.6rem;
      margin-bottom: 1.1rem;

      th {
        padding: 0;
        font-weight: 400;
        font-size: 1.8rem;

        &:last-child {
          white-space: nowrap;
          vertical-align: top;
        }
      }
    }

    ul {
      list-style-type: disc;
      padding-left: 3.2rem;
      padding-bottom: 3.2rem;

      li {
        margin-bottom: 0.5rem;
      }
    }

    hr {
      margin: 0;
      border: none;
    }
  }
}
