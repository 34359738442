.guideline-list__header {
  padding: $gap $gap 1rem;

  span {
    color: $color__neutral--700;

    &:first-child {
      flex-grow: 1;
    }
  }

  .guideline-list__header-category {
    color: $color__black;
    font-weight: $font-weight--regular;
    font-size: $font-size--base;
    width: 100%;
  }

  .guideline-list__header-total {
    text-align: right;
  }

  &.guideline-history {
    padding-left: $spacing-el-tiny;
    padding-right: $spacing-el-tiny;
  }
}
