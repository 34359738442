.layout__dashboard {
  .dashboard__header {
    width: 100%;
  }

  .dashboard__main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 65px);

    .dashboard__content {
      margin-top: $spacing-el-medium;
      flex: 0 0 calc(60% - #{$spacing-el-small});
      overflow: scroll;

      > * + * {
        margin-top: $spacing-el-large;
      }
    }

    .dashboard__bookmarks {
      flex: 0 0 calc(40% - #{$spacing-el-small});
      height: inherit;
    }
  }
}
