.login {
  width: 75%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacing-el-medium;
  background-color: $color__neutral--25;
  border-radius: $border-radius;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 5%);

  .logo-login {
    display: flex;
    justify-content: center;

    img {
      max-width: 150px;
    }
  }

  .login__fields {
    display: flex;
    flex-direction: column;
    padding: $spacing-el-small 0;

    .textfield:not(:first-child) {
      margin-top: $spacing-el-xsmall;
    }
  }

  .invalid-feedback {
    color: $color__error--900;
    font-size: $font-size--secondary;
    margin-top: $spacing-el-tiny;
  }

  .login__button {
    display: flex;

    button {
      flex: 1 0 100%;
    }
  }
}

.login__reset {
  margin-top: $spacing-el-small;
}
