.edit-collection__item {
  form {
    width: 100%;

    .btn--primary {
      opacity: 0;
    }

    &:hover {
      .btn--primary {
        opacity: 1;
      }
    }

    textarea {
      flex: 1;
    }
  }

  .edit-collection__item-name {
    flex-grow: 1;
    padding-right: $spacing-el-default;

    textarea {
      width: 100%;

      &.category-header {
        color: #1d1d25;
        font-weight: 400;
        font-size: $font-size--base;
      }
    }
  }
}

// guideline block within each category
.editable-guideline-frame {
  &:nth-child(2n + 1) {
    li {
      background-color: $color__neutral--25;
    }
  }

  form {
    padding: 1.2rem 0.6rem;
  }
}

.edit-collection__category-list + h4 {
  margin-top: $spacing-el-medium;
}
