.bookmarks__action {
  display: flex;
  align-items: center;
  font-size: $font-size--small;
  margin-right: $spacing-el-small;

  &.action--active {
    color: $color__green--light;

    .icon {
      color: $color__green--light;
    }
  }
}
