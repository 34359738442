.request-item {
  transition: all 150ms ease;

  &:hover {
    background-color: rgba($color__attention--500, 5%);
  }

  & + .request-item {
    border-top: 1px solid $color__neutral--200;
  }

  a {
    display: flex;
    flex-direction: column;
    padding: $spacing-el-default;
    border-bottom: none;
  }

  small {
    color: $color__black;
    font-weight: $font-weight--light;
    line-height: 140%;
  }

  .request-item__header {
    display: flex;
    justify-content: space-between;

    .request-header__name {
      font-weight: $font-weight--medium;
    }
  }

  .request-item__guideline {
    margin-top: $spacing-el-tiny;

    small {
      display: block;
    }

    .request-guideline__cat {
      text-transform: uppercase;
      color: $color__neutral--700;
    }
  }

  .request-item__body {
    margin-top: $spacing-el-default;
    user-select: all;

    p {
      color: $color__black;
    }
  }
}

.request-item--default {
  background-color: $color__success--50;
}

.request-item--active {
  background-color: $color__attention--50;
}

.request-item--viewed {
  background-color: transparent;
}

.request-item--archived {
  opacity: 0.5;
}
