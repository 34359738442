.autocomplete .results li.client {
  &.client__integrated {
    padding-left: 28px;
    position: relative;

    &::before {
      height: 16px;
      width: 16px;
      display: block;
      text-align: center;
      position: absolute;
      left: 6px;
      top: 8px;
      content: '';
      background-size: 16px 16px;
      background-repeat: no-repeat;
    }
  }

  &.altfee::before {
    background-image: url('images/logo-altfee.svg');
  }

  &.clio::before {
    background-image: url('images/logo-clio.svg');
  }
}
