@media print {
  // ensure that all content prints
  * {
    overflow: visible !important;
    color-adjust: exact;
    -webkit-print-color-adjust: exact !important;
  }

  // override 100% height on container
  body,
  html,
  .layout,
  .content__primary.scroll,
  .content__secondary.scroll {
    height: unset !important;
  }

  // margin override in development and production
  body {
    margin-top: 60px !important;
    margin-bottom: 24px;

    // AltFee logo in header
    &::before {
      height: 24px;
      margin: 12px auto;
      display: block;
      width: 50%;
      min-width: 300px;
      text-align: center;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      content: url('images/logo-altfee-text.svg');
      padding-bottom: 12px;
      border-bottom: 2px solid $color__neutral--700;
      top: 0;
    }
  }

  // hide unnecessary UI elements
  section.main-menu,
  .page-header__nav-back,
  .add-members-link,
  form.insights-delete,
  form.project-member-remove,
  .development:before,
  .test:before {
    display: none !important;
  }

  .guideline__main {
    flex-direction: column;
  }

  // define content blocks that shouldn't be
  // broken over multiple pages
  .guideline-item {
    break-inside: avoid-page;
  }

  // minor change to Update Requests layout to make it
  // at least legible when printed
  .updates__main {
    flex-direction: column;

    .updates__list {
      padding-bottom: $spacing-el-large;
      border-right: none !important;
      border-bottom: 1px solid $color__neutral--500;
    }

    .update-request__header {
      display: none;
    }

    button {
      display: none;
    }
  }

  .card {
    background: white;

    &:last-of-type {
      margin-bottom: $spacing-el-small;
    }
  }

  // Matters view
  body.matters {
    .content__primary {
      order: 2;
    }

    .content__secondary {
      order: 1;
      display: flex;
      flex-direction: column;

      // matter fee totals card
      .card {
        margin-top: 0;
        margin-bottom: $spacing-el-small;

        &:first-of-type {
          order: 2;
        }
      }

      // matter info card
      .card:nth-child(2) {
        order: 1;
      }
    }

    // remove accordion functionality
    .accordion {
      flex: none;
      height: unset;
      margin-bottom: 16px;
    }

    .accordion-caret {
      display: none;
    }

    .accordion__collapsed {
      > * {
        display: inline-block;
        width: 100%;
      }

      // small exception for the individual fee totals block
      > .flex--align-end {
        display: flex;
      }

      h2 {
        color: $color__primary--900;
      }
    }

    textarea {
      height: auto !important;
      overflow: hidden;
    }

    #notes_form,
    .guideline-actions *,
    .add-factor,
    .matter-actions {
      display: none;
    }
  }
}
