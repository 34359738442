.tooltip {
  position: relative;
  cursor: pointer;

  .tooltip__tip {
    display: none;
    position: absolute;
    z-index: 1;
    padding: 1.4rem 1.7rem;
    background: $color__primary--25;
    pointer-events: none;
    transition: opacity 0.2s ease;
    width: max-content;
    max-width: 40rem;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 15%),
      0px 4px 8px rgba(0, 0, 0, 20%);
    border: 1px solid $color--fainter-border;
    border-radius: $border-radius;
    font-size: $font-size--base;

    .tooltip__arrow,
    .tooltip__arrow:before,
    .tooltip__arrow:after {
      position: absolute;
      width: 1rem;
      height: 1rem;
      background: inherit;
    }

    .tooltip__arrow {
      visibility: hidden;
    }

    .tooltip__arrow:before {
      visibility: visible;
      content: '';
      z-index: 2;
    }

    .tooltip__arrow:after {
      visibility: visible;
      content: '';
      transform: rotate(45deg);
      box-shadow:
        0px 1px 2px rgba(0, 0, 0, 15%),
        0px 4px 8px rgba(0, 0, 0, 20%);
      border: 1px solid $color--fainter-border;
    }

    &[data-position='top'] > .tooltip__arrow {
      &:before {
        width: 2.3rem;
        height: 1.4rem;
        top: -0.9rem;
        left: -0.6rem;
      }
    }

    &[data-position='bottom'] > .tooltip__arrow {
      &:after {
        top: -0.1rem;
      }

      &:before {
        width: 2.5rem;
        height: 1.4rem;
        top: 0.5rem;
        left: -0.8rem;
      }
    }

    &[data-position='left'] > .tooltip__arrow {
      &:before {
        width: 1.4rem;
        height: 2.5rem;
        top: -0.7rem;
        left: -0.9rem;
      }
    }

    &[data-position='right'] > .tooltip__arrow {
      &:after {
        left: -0.1rem;
      }

      &:before {
        width: 1.4rem;
        height: 2.3rem;
        top: -0.6rem;
        left: 0.5rem;
      }
    }

    p {
      white-space: normal;
    }
  }
}

.card:has(.tooltip) .tooltip {
  position: relative;

  &:hover {
    z-index: 10;
  }
}
