.estimate-modifier__name {
  flex-grow: 1;
  font-weight: $font-weight--light;
}

.estimate-modifier__modifier-option {
  select {
    width: 32.5rem;
  }
}
