.placeholder {
  background-color: $color__attention--100;
  border: 1px solid $color__attention--700;
  padding: 1.2rem;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  gap: 12px;

  .placeholder--content {
    line-height: 0;
  }

  .icon {
    color: $color__attention--700;
    position: relative;
    top: 0.2rem;
  }

  small {
    margin-bottom: 0;
    color: $color__attention--900;
  }

  li {
    font-size: $font-size--small;
    line-height: 2rem;
    color: $color__attention--900;
  }

  &.error {
    background-color: $color__error--100;
    border-color: $color__error--700;

    .icon {
      color:  $color__error--700;
    }

    small {
      color: $color__error--900;
    }
  }
}
