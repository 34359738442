.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner img {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
