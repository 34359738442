// Media Query Sizes
// ------------------------------
$phone-only: 425px;
$tiny-only: 525px;
$small-only: 899px;
$medium-up: 900px;
$medium-only: 1199px;
$large-up: 1200px;
$huge-up: 1800px;

// Colours
// ------------------------------

$color__neutral--900: #1d1d25;
$color__neutral--800: #2b2b2b;
$color__neutral--700: #6b6b6b;
$color__neutral--600: #7f7f7f;
$color__neutral--500: #949494;
$color__neutral--400: #aeaeae;
$color__neutral--300: #c0c0c0;
$color__neutral--200: #d6d6d6;
$color__neutral--100: #e6e6e6;
$color__neutral--50: #efefef;
$color__neutral--25: #fafafa;
$color__neutral--10: #fcfcfc;

$color__primary--900: #103f45;
$color__primary--700: #1a6c59;
$color__primary--500: #2eae8f;
$color__primary--300: #96d6c6;
$color__primary--100: #caeae3;
$color__primary--50: #def3ee;
$color__primary--25: #ebf7f4;

$color__success--900: #147ead;
$color__success--700: #1babec;
$color__success--500: #54c1f1;
$color__success--300: #8dd5f5;
$color__success--100: #c6eafa;
$color__success--50: #f0f9fd;

$color__attention--900: #5c2d18;
$color__attention--700: #e8871e;
$color__attention--500: #ed7444;
$color__attention--300: #edb458;
$color__attention--100: #faecd5;
$color__attention--50: #fdeae3;

$color__error--900: #c10502;
$color__error--700: #ff495c;
$color__error--500: #f86277;
$color__error--300: #fa95a4;
$color__error--100: #fccad1;

// Colours: app standards
// ------------------------------

// default colours
$color__black: $color__neutral--900;
$color__white: #ffffff;

// default secondary type colour
$color__grey: $color__neutral--700;

// default link colour
$color__green: $color__primary--700;

// default highlight/active colour
$color__green--light: $color__primary--500;

// Borders
// ------------------------------
$border-radius: 4px;
$color--fainter-border: #cccccc;

// Padding & Margins
// ------------------------------
$spacing: 1.6rem;
$gap: 1.6rem;

// TODO: phase the rest of these out
$spacing-el-tiny: 6px;
$spacing-el-xsmall: 10px;
$spacing-el-small: 16px;
$spacing-el-default: 20px;
$spacing-el-medium: 32px;
$spacing-el-large: 48px;
$spacing-el-xlarge: 64px;
