#user_management {
  table {
    td {
      &.user-role {
        text-align: right;
        color: $color__neutral--500;
      }

      &:last-child {
        text-align: center;
      }
    }
  }
}
