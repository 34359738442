.guideline-index__list {
  margin-bottom: $spacing-el-large;

  .guideline-index__practice-area {
    > .list-item--link {
      &:nth-child(2n + 1) {
        background-color: $color__neutral--25;

        &:hover {
          background-color: $color__neutral--50;
        }
      }
    }

    & + h4 {
      margin-top: $spacing-el-medium;
    }
  }

  h4 {
    padding: 0 $gap;
  }
}
