@import 'icons';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

$fonts-rubik: 'Rubik', 'Helvetica Neue', helvetica, arial, sans-serif;
$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--medium: 500;

$font-size--base: 1.6rem;
$font-size--secondary: 1.4rem;
$font-size--small: 1.2rem;

$font-size--h1: 3.2rem;
$font-size--h2: 2.6rem;
$font-size--h3: 2.3rem;
$font-size--h4: 2rem;

$line-height--base: 2.4rem;

@mixin title-styles {
  margin: 0;
}

h1,
.h1 {
  @include title-styles;
  font-size: $font-size--h1;
  font-weight: $font-weight--medium;
  line-height: 4rem;
}

h2 {
  @include title-styles;
  font-size: $font-size--h2;
  font-weight: $font-weight--medium;
  line-height: 4.8rem;
}

h3 {
  @include title-styles;
  font-size: $font-size--h3;
  font-weight: $font-weight--medium;
  line-height: 4rem;
}

h4 {
  @include title-styles;
  font-size: $font-size--h4;
  font-weight: $font-weight--regular;
  line-height: 3.2rem;
}

h5 {
  @include title-styles;
  font-size: $font-size--base;
  font-weight: $font-weight--regular;
  line-height: $line-height--base;
}

p {
  font-size: $font-size--base;
  font-weight: $font-weight--light;
  line-height: $line-height--base;
  margin-top: 0px;
  margin-bottom: $spacing-el-tiny;

  &.secondary {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: $font-size--secondary;
    line-height: 2rem;
  }
}

p + p {
  margin-top: $spacing-el-small;
}

li {
  font-weight: $font-weight--light;
  font-size: $font-size--base;
  line-height: 140%;
}

small {
  font-size: $font-size--small;
  font-weight: $font-weight--light;
  line-height: 2rem;
}

a {
  color: $color__green;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease;
  display: inline-block;

  i {
    margin-right: $spacing-el-tiny;
    font-size: 1.6rem;
  }

  &:hover {
    border-bottom: 1px solid $color__green;
  }

  &.unstyled-link {
    border-bottom: none;
    font-weight: $font-weight--regular;
  }

  &.external-link {
    i {
      margin-left: 0.4rem !important;
    }
  }

  &.danger {
    color: $color__error--900;

    &:hover {
      border-color: currentColor;
    }
  }

  &.secondary {
    font-size: $font-size--secondary;
  }
}

.link--smaller {
  font-size: 1.4rem;
  line-height: 1.9rem;
  display: inline-flex;

  .icon {
    font-size: 1.4rem;
    line-height: inherit;
  }
}

ul.list-bulleted {
  list-style-type: circle;
  padding-left: $spacing-el-small;
}

.labelled_datalist {
  .label {
    font-size: $font-size--secondary;
    font-weight: $font-weight--light;
    text-transform: uppercase;
    line-height: 2rem;
  }

  .data {
    font-weight: $font-weight--light;
    font-size: $font-size--secondary;
    line-height: 2rem;
  }
}
