#search-form {
  input {
    font-size: $font-size--base;
    line-height: $line-height--base;
  }

  .toggle {
    margin-top: $spacing-el-small;
    height: $spacing-el-small;
  }
}
