.autocomplete {
  position: relative;

  [type='text'] {
    padding-right: 4rem;
  }

  .close {
    display: none;
    position: absolute;
    right: 0;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 28px;
  }

  [data-autocomplete-target='hidden'][value] + .close {
    display: block;
    opacity: 0.5;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .results {
    position: absolute;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    z-index: 10;
    max-height: 248px;
    overflow: auto;

    li {
      background-color: white;
      padding: $spacing-el-tiny;
      color: $color__black;
      cursor: pointer;
      transition: all 150ms ease;

      &.active {
        background-color: $color__neutral--50;
        color: $color__green--light;
      }
    }
  }
}

fieldset {
  .form-element-wrapper {
    &.required {
      label span:after {
        content: '*';
        color: $color__error--900;
      }
    }

    textarea {
      width: 100%;
      resize: vertical;
    }
  }

  * + .form-element-wrapper {
    margin-top: $spacing-el-small;
  }
}

select {
  background-color: white;
}

input[type='text'],
input[type='number'],
textarea {
  &.invalid {
    border: 1px solid $color__error--500 !important;
    background-color: $color__error--100;
  }
}

.dropdown-container {
  display: flex;
  align-items: center;
  position: relative;

  .dropdown-menu {
    transition: opacity 0.2s linear;
    display: flex;
    flex-direction: column;
    border: 1px solid $color__neutral--200;
    background: white;
    border-radius: 4px;
    z-index: 101;
    opacity: 1;
    position: absolute;
    top: 100%;
    right: 0;
    width: max-content;

    @media (max-width: $tiny-only) {
      left: 0;
    }

    &.hidden {
      opacity: 0;
      display: none;
    }

    .bookmarks__action {
      margin-right: 0;

      form {
        width: 100%;
      }

      button {
        padding: 0.5rem 1.6rem 0.5rem 2rem;
        width: 100%;
        justify-content: flex-start;
        font-size: $font-size--base;
        line-height: $line-height--base;
      }

      &:hover {
        background-color: $color__neutral--50;
        cursor: pointer;

        button {
          color: $color__green--light;
        }
      }

      button {
        color: $color__green;
      }
    }

    a {
      padding: 0.6rem 1.6rem;
      border-bottom: none;

      i {
        margin-left: -0.2rem;
      }

      &.danger {
        color: $color__error--900;
      }

      &:hover {
        border-bottom: none;
        background-color: $color__neutral--50;
        color: $color__green--light;

        &.danger {
          background-color: $color__error--100;
          color: $color__error--900;
        }
      }
    }
  }
}

.altfee-radio {
  display: inline-block;

  input {
    appearance: none;
    display: none;

    + span {
      display: inline-block;
      vertical-align: middle;
      width: 1.4rem;
      height: 1.4rem;
      border: 1px solid $color__neutral--700;
      border-radius: 50%;
      background: $color__white;
      transition: background 0.3s ease;
    }

    &:checked + span {
      background: $color__green;
    }
  }
}

.pill {
  padding: 6px;
  border-radius: 4px;
  font-weight: $font-weight--light;
  font-size: $font-size--secondary;

  span {
    font-weight: $font-weight--regular;
  }

  &.pill--status-complete {
    background: $color__success--50;
  }

  &.pill--status-incomplete {
    background: $color__attention--100;
  }

  &.pill--status-beta {
    background: $color__success--100;
    padding: 4px 12px;
    align-self: center;
    border-radius: 8px;

    span {
      color: $color__neutral--600;
    }
  }
}
