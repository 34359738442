.pagination {
  display: flex;
  justify-content: center;
  padding: 2rem;

  .first,
  .prev,
  .page,
  .next,
  .last {
    padding: 0 0.5rem;
  }
}
