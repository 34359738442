.main-menu {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  height: 100vh;
  background-color: $color__neutral--25;
  border-right: 1px solid $color--fainter-border;
  padding: 2.4rem 1.6rem;
  transition:
    width 150ms ease,
    padding 150ms ease;
  flex: 0 0 7.2rem;

  @media (max-width: $medium-only) {
    &:hover {
      flex: 0 0 25.5rem;
      z-index: 100;
      box-shadow: 4px 0 4px rgba(0, 0, 0, 5%);

      a {
        justify-content: initial;
      }

      .show-mobile {
        display: none;
      }

      .hide-mobile {
        display: block;
      }

      + main {
        flex: 1 0 calc(100vw - 25.5rem);
      }
    }
  }

  @media (min-width: $large-up) {
    flex: 0 0 25.5rem;
  }

  .root {
    justify-content: center;
    margin: 0 0.4rem 1.8rem;
    padding: 0;

    &:hover {
      text-decoration: none;
      border-bottom: none;
      background-color: transparent;

      img {
        transform: scale(1.025);
      }
    }

    @media (min-width: $large-up) {
      justify-content: initial;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin-bottom: 0.2rem;

    &.menu-primary {
      flex-grow: 1;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color__black;
    font-size: 1.4rem;
    text-decoration: none;
    border-bottom: none;
    padding: 0.6rem 1rem;
    border-radius: 0.4rem;
    transition: background-color 150ms ease;

    &:not(.root, .edit_user) span {
      line-height: 1.6rem;
      white-space: nowrap;
      margin-left: 1.2rem;
    }

    .icon {
      padding: 0.3rem;
      margin: -0.3rem;
      color: $color__neutral--500;
      border-radius: 50%;
      transition:
        background-color 150ms ease,
        color 150ms ease;
    }

    &.active,
    &:hover {
      background-color: $color__neutral--50;

      .icon {
        color: $color__black;
      }
    }

    &.highlight .icon {
      color: $color__white;
      background-color: $color__attention--500;
    }

    @media (min-width: $large-up) {
      justify-content: initial;
    }
  }

  .edit_user {
    border-radius: 0.8rem;
    background-color: $color__white;
    transition: background-color 150ms ease;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%);
    padding: 0.8rem 0.6rem;

    span {
      margin-left: 0.8rem;
    }

    &:hover .user-avatar {
      background-color: $color__white;
    }
  }

  .admin_user {
    border-radius: 0.8rem;
    background-color: $color__attention--300;
    transition: background-color 150ms ease;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%);
    padding: 0.8rem 0.6rem;

    span {
      margin-left: 0.8rem;
    }

    .user-avatar {
      background-color: $color__attention--300;
    }

    &:hover .user-avatar {
      background-color: $color__neutral--50;
    }
  }
}
