.estimate {
  .estimate__header {
    display: flex;
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 3.2rem;

    .estimate__name {
      flex: 1 1 100%;
      display: flex;

      .icon {
        color: $color__green--light;
      }
    }

    .estimate__total {
      flex: 1;
      white-space: nowrap;

      .project-total {
        gap: 1.8rem;
      }

      .project-total__overall {
        &::first-letter {
          color: $color__neutral--500;
        }
      }

      .icon {
        color: $color__primary--700;
      }
    }
  }

  .estimate__tabs {
    border-bottom: 1px solid $color__neutral--100;
    padding-bottom: 8px;
    display: flex;
    gap: 0.8rem;

    [type='radio'] {
      display: none;
    }

    label {
      display: inline-block;
      color: $color__neutral--700;
      padding: 0.6rem 0.8rem;
      cursor: pointer;
      border-radius: $border-radius;
      transition:
        color 0.2s ease,
        background-color 0.2s ease;

      &.active,
      &:hover {
        color: $color__primary--900;
        background-color: $color__primary--25;
      }
    }
  }

  .estimate__content {
    .estimate__factors,
    .estimate__modifiers,
    .estimate__service_descriptions {
      display: none;

      &.active {
        display: block;
      }
    }

    .factors__list:not(:empty) + .placeholder,
    .estimate_modifiers__list:not(:empty) + .placeholder,
    .service_descriptions__list:not(:empty) + .placeholder {
      display: none;
    }

    .estimate__base-fee {
      display: flex;
      align-items: center;
      padding: 0.6rem 0;
      border-bottom: 1px solid $color__neutral--100;

      .text {
        flex: 1;
        font-weight: 400;
        text-indent: 0.7rem;
      }

      .base-fee {
        position: relative;

        span {
          color: $color__neutral--400;
          font-weight: $font-weight--light;
        }

        input {
          @include factorInputStyle();
          width: 85px;
          max-width: 85px;
          margin-right: 3.1rem;
          text-align: right;
        }
      }
    }
  }

  .estimate__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 3.2rem;
    margin-left: -1rem;
    margin-right: -1rem;

    > div {
      padding: 0 1rem;
    }

    .view-guideline {
      flex-grow: 1;
    }
  }
}

.estimate.locked {
  .checkbox,
  input,
  textarea,
  select {
    pointer-events: none;
  }

  .factor__edit {
    opacity: 0;
    pointer-events: none;
  }

  .draggable .handle {
    display: none;
  }

  &.invoiced {
    .selectable:not(.selected),
    .placeholder,
    .estimate__actions {
      display: none;
    }

    .checkbox span {
      border-color: $color__neutral--500;
      background: $color__neutral--500;
    }
  }
}
