.statuses {
  label {
    cursor: pointer;

    span::last-child {
      flex-grow: 1;
    }
  }

  &.locked {
    input,
    label {
      pointer-events: none;
    }
  }
}
