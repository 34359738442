html,
body {
  height: 100%;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  position: relative;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-size: $font-size--base;
  line-height: $line-height--base;
  font-family: $fonts-rubik;
  font-weight: $font-weight--regular;
  background-color: $color__neutral--25;
}

img {
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
button {
  cursor: pointer;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: $color__green transparent;
  scroll-behavior: smooth;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-button {
    width: 1px;
    height: 1px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $color__green;
  }
}

.scroll-lg {
  @media (min-width: $large-up) {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: $color__green transparent;
    scroll-behavior: smooth;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
      width: 1px;
      height: 1px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $color__green;
    }
  }
}

.print-only {
  display: none;
}

@media print {
  .print-only {
    display: flex;
  }
}

// colour classes
.color-green {
  color: $color__primary--900;
}

.color-green-light {
  color: $color__green--light;
}

.color-orange {
  color: $color__attention--500;
}

.color-grey {
  color: $color__neutral--700;
}

.color-grey-light {
  color: $color__neutral--500;
}

.color-black {
  color: $color__black;
}

// error classes
.error-alert {
  background-color: $color__error--100;
  border: 1px solid $color__error--500;
  padding: $spacing;
  border-radius: $border-radius;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 12px;
  margin-bottom: $spacing;

  i {
    font-size: 1.6rem;
    color: $color__error--900;
    margin-top: 2px;
  }

  li {
    color: $color__error--900;
    font-weight: $font-weight--regular;
    font-size: $font-size--secondary;
  }
}

.error-highlight {
  background-color: $color__error--100;
}

// mobile show/hide classes
.hide-mobile {
  @media (max-width: $medium-only) {
    display: none;
  }
}

.show-mobile {
  display: none;

  @media (max-width: $medium-only) {
    display: inline-block;
  }
}

.error-page {
  width: 80%;
  max-width: 600px;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo {
    margin-bottom: 3rem;
    img {
      width: 15rem;
      height: 3.4rem;
    }
  }

  h1 {
    font-weight: 700;
    color: #31d0aa;
    font-size: 6rem;
    line-height: 5rem;
    margin: 0;
  }

  h2 {
    font-weight: 500;
    color: $color__primary--900;
    font-size: 3rem;
    margin: 0;
  }

  p {
    font-size: 2rem;
    color: $color__black;
    margin-bottom: 3rem;
  }
}

@mixin inputStyle {
  border: none;
  resize: none;
  color: $color__black;
  border: 1px solid $color__neutral--200;
  border-radius: $border-radius;
  transition: all 0.2s ease;
  font-weight: $font-weight--light;
  padding: 0.7rem;
  font-size: $font-size--secondary;
  line-height: 2rem;
}

@mixin factorInputStyle() {
  @include inputStyle();
  font-size: $font-size--base;
  line-height: 2.4rem;
  border: 1px solid transparent;

  &:focus,
  &:hover {
    outline: none !important;
    border: 1px solid $color__green;
    box-shadow: 0 0 0 2px $color__primary--50;
  }
}
