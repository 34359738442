.checkbox-item {
  display: flex;
  align-items: flex-start;

  .checkbox {
    margin-right: $spacing-el-xsmall;
    padding-bottom: 0;
  }

  & + .checkbox-item {
    margin-top: $spacing-el-tiny;
  }
}

.checkbox {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    background-color: white;

    &:first-child {
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      transform: scale(1);
      vertical-align: middle;
      border: 1px solid $color__neutral--500;
      transition: all 0.2s ease;

      svg {
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 3px;
        fill: none;
        stroke: white;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: $color__green;
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 50%;
        transition-delay: 0.2s;
      }
    }

    &:last-child::after {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      height: 1px;
      width: 100%;
      background: lighten($color__attention--500, 75%);
      transform-origin: 0 0;
      transform: scaleX(0);
    }
  }

  &:hover span:first-child {
    border-color: $color__attention--500 !important;
    background-color: $color__attention--100;
  }
}

.input-checkbox {
  display: none;
}

// hovering a checked checkbox
.input-checkbox:checked + .checkbox:hover span:first-child {
  border-color: $color__attention--500 !important;
  background-color: $color__attention--500;
}

.input-checkbox:checked + .checkbox span:first-child {
  border-color: $color__green;
  background: $color__green;
  animation: check 0.6s ease;
}

.input-checkbox:checked + .checkbox span:first-child svg {
  stroke-dashoffset: 0;
}

.input-checkbox:checked + .checkbox span:first-child::before {
  transform: scale(2.2);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes check {
  50% {
    transform: scale(1.2);
  }
}
