.draggable {
  &:hover .handle {
    opacity: 0.5;
  }

  .handle {
    position: absolute;
    line-height: 0;
    padding: 0.85rem 1rem;
    left: -3.4rem;
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: grab;
    color: $color__green;

    &:hover {
      opacity: 1;
    }
  }

  &.draggable-source--is-dragging {
    pointer-events: none;
    opacity: 0.5;
  }
}

.draggable-mirror {
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
}

// handle for re-ordering matter projects
// needs a slightly different position
.project_price.draggable .handle {
  left: -1.9rem;
}
